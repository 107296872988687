import styled from 'styled-components';

const TextInputGroup = styled.div`
  margin: 0 0 20px;
  padding: 0;
`;

const TextInputField = styled.input`
  display: block;
  border-radius: 0;
  width: 100%;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 300;
  font-family: 'Pacaembu VF';
  margin: 0;
  background: none;
  border: none;
  border-bottom: 1px solid ${({theme}) => theme.colors.primary.regular};
  color: ${({dark, theme}) => dark ? theme.colors.text.regular : theme.colors.foreground.regular};

  &::-webkit-input-placeholder {
    color: ${({dark, theme}) => dark ? theme.colors.text.regular : theme.colors.foreground.regular};
    opacity: 0.4;
  }
  &::-moz-placeholder {
    color: ${({dark, theme}) => dark ? theme.colors.text.regular : theme.colors.foreground.regular};
    opacity: 0.4;
  }
  &:-ms-input-placeholder {
    color: ${({dark, theme}) => dark ? theme.colors.text.regular : theme.colors.foreground.regular};
    opacity: 0.4;
  }
  &:-moz-placeholder {
    color: ${({dark, theme}) => dark ? theme.colors.text.regular : theme.colors.foreground.regular};
    opacity: 0.4;
  }

  &:invalid {
    border-bottom-color: ${({theme}) => theme.colors.danger.regular};
    outline: none;
    box-shadow: none;
  }

  &:focus {
    border-bottom-color: ${({dark, theme}) => dark ? theme.colors.primary.hover : theme.colors.secondary.regular};
    outline: none;
    box-shadow: none;
  }
  
  &:disabled {
    opacity: 0.5;
  }
`;

const TextInputLabel = styled.label`
  display: block;
  margin: 0;
  padding: 0;
  font-size: 0.9rem;
  font-weight: 700;
`;

export {
  TextInputGroup,
  TextInputField,
  TextInputLabel
}
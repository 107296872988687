import React, {useState} from 'react';
import ReactGA from 'react-ga';
import {useTranslation} from 'react-i18next';
import useGoogleSheetPoster from '../../requesters/useGoogleSheetPoster';
import Button from '../../components/button';
import Checkbox from '../../components/checkbox';
import TextInput from '../../components/textInput';
import {
  FormContainer,
  FormText
} from './style';

const Form = ({action}) => {
  const {t} = useTranslation();
  const [confirm, toggleConfirm] = useState(false);
  const [postData, pending, error] = useGoogleSheetPoster();
  const [name, handleName] = useState('');
  const [email, handleEmail] = useState('');
  const [acknowledge, toggleAcknowledge] = useState(false);
  const [newsletter, toggleNewsletter] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    ReactGA.event({category: 'Download trial fonts', action: 'Submit form to trial fonts', label: 'Trial fonts form'});

    postData({
      payload: [name, email, newsletter],
      callback: () => toggleConfirm(true)
    });
  }

  const finishModalJourney = () => {
    ReactGA.event({category: 'Download trial fonts', action: 'Download trial font pack after form', label: 'Trial fonts form'});
    action();
  }

  const checkTrialLicenceTracker = () => ReactGA.event({category: 'Check licence', action: 'Check trial font licence in form', label: 'Trial fonts form'});

  if (confirm) {
    return (
      <div>
        <p>Click the button below to download your font trial.</p>
        <p><Button href="/bucket/Pacaembu_Trial.zip" download onClick={finishModalJourney} label="Download trial font"/></p>
      </div>
    );
  } else {
    return (
      <FormContainer onSubmit={handleSubmit}>
        <FormText>        
          <p>Test Pacaembu before you buy with free trial fonts. By using trial fonts you agree to restrict this use for testing and mock-ups only. See our <a href="http://www.naipe.xyz/TrialEULA.pdf" target="_blank" rel="noopener noreferrer" onClick={checkTrialLicenceTracker}>Trial License</a> for details.</p>
          <p>Students <strong>may use these in school work,</strong> but <strong>not commercial work.</strong> Use on social media for personal projects is permitted with an <strong>attribution, mention and compliment.</strong> For example “Font used is Pacaembu by @naipe.xyz, a font so good I can’t believe I’ve lived this long without it”.</p>
          <p>Use for social justice, fighting racism, fascism, colonialism or sexism is permitted and encouraged, no credit necessary. <span role="img" aria-label="Fist">&#x270A;</span></p>
          <p><strong>Contains:</strong></p>
          <ul>
            <li>AÁÂÃÀBCÇDEÉÊFGHIÍJKL</li>
            <li>MNOÓÔÕPQRSTUÚVWXYZ</li>
            <li>aáâãàbcçdeéêfghiíjkl</li>
            <li>mnoóôõpqrstuúvwxyz</li>
            <li>0123456789,.[space]</li>
            <li>No kerning. No opentype features.</li>
            <li>.OTF and .WOFF files.</li>
          </ul>
        </FormText>
  
        <TextInput
          name="name"
          value={name}
          onChange={({target}) => handleName(target.value)}
          placeholder={t('form.namePlaceholder')}
          dark
        />
  
        <TextInput
          name="email"
          value={email}
          onChange={({target}) => handleEmail(target.value)}
          placeholder={t('form.emailPlaceholder')}
          type="email"
          dark
        />
  
        <Checkbox
          name="trial-agreement"
          value={acknowledge}
          onChange={() => toggleAcknowledge(!acknowledge)}
          label={t('form.acknowledge')}
          dark
        />
        
        <Checkbox
          name="newsletter"
          value={newsletter}
          onChange={() => toggleNewsletter(!newsletter)}
          label={t('form.newsletter')}
          dark
        />
  
        {error && <p>{JSON.stringify(error)}</p>}
  
        <Button type="submit" disabled={!acknowledge || email === '' || name === '' || pending} label={t('form.submit')} />
      </FormContainer>
    );
  }
}

export default Form;
export default {
  colors: {
    primary: {
      regular: '#00CCAD',
      hover: '#00B398',
      active: '#008C77'
    },
    secondary: {
      regular: '#FFDD00',
      hover: '#E6C700',
      active: '#BFA600'
    },
    info: {
      regular: '#0B89D9',
      hover: '#0A7ABF',
      active: '#086199'
    },
    success: {
      regular: '#0BD945',
      hover: '#0ABF3D',
      active: '#089931'
    },
    warning: {
      regular: '#FFA20D',
      hover: '#E6920B',
      active: '#BF7A0A'
    },
    danger: {
      regular: '#ED0044',
      hover: '#D4003C',
      active: '#AD0031'
    },
    selected: {
      regular: '#FF6666',
      hover: '#EE5555',
      active: '#DD4444'
    },
    text: {
      regular: '#333333',
      hover: '#222222',
      active: '#111111'
    },
    foreground: {
      regular: '#FFFFFF',
      hover: '#EEEEEE',
      active: '#DDDDDD'
    },
    background: {
      regular: '#333333',
      hover: '#222222',
      active: '#111111'
    },
    backgroundAlt: {
      regular: '#335544',
      hover: '#233B2F',
      active: '#1E3329'
    }
  },
  breakpoints: {
    moments: '899px',
    history: '767px',
    default: '639px'
  }
}

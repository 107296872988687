import {useState} from 'react';
import axios from 'axios';

const ENDPOINT = process.env.REACT_APP_GOOGLE_SHEETS_ENDPOINT;
const SHEET    = process.env.REACT_APP_SHEET_ID;

export default () => {
  const [error, handleError] = useState(null);
  const [pending, togglePending] = useState(false);

  const postData = ({payload, callback}) => {
    togglePending(true);

    axios.post(ENDPOINT, [payload], {params: {tabId: SHEET}})
      .then(res => {
        if (callback) callback(res);
      })
      .catch(err => {
        handleError(err);
        console.log(err);
      })
      .finally(() => togglePending(false));
  }

  return [postData, pending, error];
}
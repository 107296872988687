import React from 'react';
import {
  ModalBackdrop,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalContent
} from './style';

const CloseIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40">
    <circle cx="20" cy="20" r="20" fill="currentColor" />
    <line x1="10" x2="30" y1="10" y2="30" stroke="white" strokeWidth="2px" />
    <line x1="10" x2="30" y1="30" y2="10" stroke="white" strokeWidth="2px" />
  </svg>
);

const Modal = ({title, active, close, children}) => {
  return (
    active &&
      <ModalBackdrop>
        <ModalBody>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <ModalClose type="button" onClick={close}><CloseIcon /></ModalClose>
          </ModalHeader>

          <ModalContent>
            {children}
          </ModalContent>
        </ModalBody>
      </ModalBackdrop>
  )
}

export default Modal;

Modal.defaultProps = {
  title: 'Title',
  children: 'Modal content'
}
import React, {forwardRef} from 'react';
import {
  ButtonUnit
} from './style';

const Button = forwardRef(({label, href, type, color, onClick, ...otherProps}, ref) => {
  if (href) {
    return (
      <ButtonUnit className="paca-button" color={color} as="a" href={href} onClick={onClick} target="_blank" rel="noopener noreferrer" ref={ref} {...otherProps}>{label}</ButtonUnit>
    );
  } else {
    return (
      <ButtonUnit className="paca-button" color={color} type={type} onClick={onClick} ref={ref} {...otherProps}>{label}</ButtonUnit>
    );
  }
});

export default Button;

Button.defaultProps = {
  label: 'Action',
  type: 'button'
}

import styled from 'styled-components';

const CheckboxGroup = styled.label`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin: 0 0 10px;
`;

const CheckboxContainer = styled.div`
  margin-right: 8px;
  position: relative;
  width: 20px;
  height: 20px;
  color: ${({dark, selected, theme}) => selected ? (dark ? theme.colors.selected.regular : theme.colors.secondary.regular) : theme.colors.primary.regular};
  cursor: pointer;

  &:hover {
    color: ${({dark, selected, theme}) => selected ? (dark ? theme.colors.selected.hover : theme.colors.secondary.hover) : theme.colors.primary.hover};
  }

  input {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }
`;

const CheckboxLabel = styled.p`
  margin: 0;
  padding: 0;
  font-size: 1rem;
  font-weight: 400px;
  letter-spacing: 0;
  cursor: pointer;
`;

export {
  CheckboxGroup,
  CheckboxContainer,
  CheckboxLabel
}
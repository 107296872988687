import React from 'react';
import {useTranslation} from 'react-i18next';
import {
  LoaderContainer,
  LoaderTitle,
  Loader
} from './style';

export default () => {
  const {t} = useTranslation();

  return (
    <LoaderContainer>
      <LoaderTitle>{t('loader.title')}</LoaderTitle>
      <Loader><span role="img" aria-label="Soccer ball">&#x26BD;</span></Loader>
    </LoaderContainer>
  );
}
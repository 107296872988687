import styled from 'styled-components';

const ModalBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background: rgba(0,0,0,0.5);
`;

const ModalBody = styled.div`
  width: calc(100% - 40px);
  max-width: 500px;
  max-height: 600px;
  box-sizing: border-box;
  background: ${({theme}) => theme.colors.secondary.regular};
  color: ${({theme}) => theme.colors.background.regular};
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 4px 8px rgba(0,0,0,0.2);
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  position: relative;
  flex: 1 0 auto;
`;

const ModalTitle = styled.h3`
  color: ${({theme}) => theme.colors.primary.regular};
  margin: 0;
  padding: 20px 30px 20px 20px;
  font-size: 1.4rem;
  font-weight: 800;
  line-height: 1.6rem;
`;

const ModalClose = styled.button`
  color: ${({theme}) => theme.colors.danger.regular};
  position: absolute;
  margin: 0;
  padding: 0;
  right: -20px;
  top: -20px;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
  cursor: pointer;

  &:hover {
    color: ${({theme}) => theme.colors.danger.hover};
  }

  * {
    pointer-events: none;
  }
`;

const ModalContent = styled.div`
  flex: 0 1 auto;
  padding: 0 20px 20px;
  overflow: hidden;
  overflow-y: scroll;
`;

export {
  ModalBackdrop,
  ModalBody,
  ModalHeader,
  ModalTitle,
  ModalClose,
  ModalContent
}
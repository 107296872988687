import React from 'react';
import {useId} from 'react-id-generator';
import {
  TextInputGroup,
  TextInputField,
  TextInputLabel
} from './style';

const TextInput = ({value, name, label, type, onChange, dark, inputRef, placeholder, ...otherProps}) => {
  const [htmlId] = useId();

  return (
    <TextInputGroup>
      {label && <TextInputLabel htmlFor={name || htmlId} dark={dark}>{label}</TextInputLabel>}
      <TextInputField type={type} id={name || htmlId} dark={dark} name={name || htmlId} value={value} onChange={onChange} ref={inputRef} placeholder={placeholder} {...otherProps} />
    </TextInputGroup>
  );
}

export default TextInput;

TextInput.defaultProps = {
  type: 'text',
  placeholder: 'placeholder'
}
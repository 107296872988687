import styled from 'styled-components';

const LoaderContainer = styled.div`
  background: ${({theme}) => theme.colors.primary.regular};
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const LoaderTitle = styled.p`
  font-size: 2rem;
  font-weight: 800;
  color: ${({theme}) => theme.colors.secondary.regular};
  text-align: center;
  margin: 0 0 10px;
`;

const Loader = styled.div`
  display: block;
  color: ${({theme}) => theme.colors.secondary.regular};
  font-size: 12rem;
  animation: spin 2s infinite;

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  span {
    vertical-align: 7px;
  }
`;

export {
  LoaderContainer,
  LoaderTitle,
  Loader
}
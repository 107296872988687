import styled from 'styled-components';

const FormContainer = styled.form`
  margin: 0 0 20px;
  padding: 0;

  p {
    margin: 0 0 10px;
  }

  a {
    color: ${({theme}) => theme.colors.primary.regular};

    &:hover,
    &:active {
      color: ${({theme}) => theme.colors.selected.regular};
    }
  }

  ul {
    list-style: none;
    margin: 0 0 10px;
    padding: 0%;
    font-size: 0.9rem;
  }

  hr {
    height: 0;
    border: none;
    border-top: 1px solid ${({theme}) => theme.colors.secondary.hover};
    margin: 25px 0;
  }
`;

const FormText = styled.div`
  margin: 0 0 30px;
`;

export {
  FormContainer,
  FormText
}
import React from 'react';
import {
  CheckboxGroup,
  CheckboxContainer,
  CheckboxLabel
} from './style';

const CheckboxIcon = ({active}) => (
  <svg width="20" height="20" viewBox="0 0 20 20">
    <rect x="1" y="1" width="18" height="18" rx="3" fill="none" stroke="currentColor" strokeWidth="2px" />
    {active && <path d="M 5 10 l 4 4 l 6 -6" fill="none" stroke="currentColor" strokeWidth="2px" strokeLinecap="round" />}
  </svg>
)

const Checkbox = ({value, name, label, dark, onChange}) => (
  <CheckboxGroup>
    <CheckboxContainer selected={value} dark={dark}>
      <CheckboxIcon active={value} />
      <input name={name} type="checkbox" value={value} checked={value} onChange={onChange} />
    </CheckboxContainer>
    <CheckboxLabel>{label}</CheckboxLabel>
  </CheckboxGroup>
);

export default Checkbox;

Checkbox.defaultProps = {
  label: 'Label'
}
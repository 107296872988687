import styled from 'styled-components';

const ButtonUnit = styled.button`
  color: ${({theme, color}) => color && ['secondary', 'foreground'].includes(color) ?  theme.colors.background.regular : theme.colors.foreground.regular};
  background: ${({theme, color}) => color ? theme.colors[color].regular : theme.colors.primary.regular};
  border: none;
  display: inline-block;
  width: max-content;
  margin: 0;
  padding: ${({small}) => small ? '4px 12px' : '8px 12px'};
  font-family: 'Pacaembu VF';
  font-size: ${({small}) => small ? '0.9rem' : '1.1rem'};
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0;
  border-radius: 99px;
  text-decoration: none;
  vertical-align: top;
  outline: none !important;
  box-shadow: ${({noShadow}) => noShadow ? 'none' : '4px 4px 0 rgba(0,0,0,0.2)'};
  transition: background 0.2s ease-in-out, transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, opacity 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: ${({theme, color}) => color ? theme.colors[color].hover : theme.colors.primary.hover};
    box-shadow: ${({noShadow}) => noShadow ? 'none' : '8px 8px 0 rgba(0,0,0,0.25)'};
    transform: ${({noShadow}) => noShadow ? 'none' : 'translate(-2px, -2px)'};
  }
  
  &:active {
    background: ${({theme, color}) => color ? theme.colors[color].active : theme.colors.primary.active};
    box-shadow: ${({noShadow}) => noShadow ? 'none' : '2px 2px 0 rgba(0,0,0,0.4)'};
    transform: none;
  }

  &:disabled {
    opacity: 0.5;
    transform: none;
    background: ${({theme, color}) => color ? theme.colors[color].regular : theme.colors.primary.regular};
    box-shadow: none;
    cursor: default;
  }

  & + [class*=${({className}) => className}] {
    margin-left: 10px;
  }

  * {
    pointer-events: none;
  }
`;

export {
  ButtonUnit
}
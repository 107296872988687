import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import './index.css';
import './fonts/fonts.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactGA.initialize(process.env.REACT_APP_GA_ID);
ReactGA.pageview(window.location.pathname + window.location.search);

Bugsnag.start({
  apiKey: process.env.REACT_APP_BUGSNAG_KEY,
  plugins: [new BugsnagPluginReact()]
});

const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React);

ReactDOM.render(<ErrorBoundary><App/></ErrorBoundary>, document.getElementById('root'));

serviceWorker.unregister();

import React, {lazy, Suspense, useState, useEffect, useRef} from 'react';
import {ThemeProvider} from 'styled-components';
import {Helmet} from 'react-helmet';
import supportsWebP from 'supports-webp';
import Theme from './settings/ThemeSettings';
import Auth from './components/loader';
import Modal from './components/modal';
import Form from './views/form';
import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import translations from './translations/';

const Menu = lazy(() => import('./components/menu'));
const Hero = lazy(() => import('./views/hero'));
const Features = lazy(() => import('./views/features'));
const History = lazy(() => import('./views/history'));
const Comparison = lazy(() => import('./views/comparison'));
const Moments = lazy(() => import('./views/moments'));
const Stadium = lazy(() => import('./views/stadium'));
const Landmark = lazy(() => import('./views/landmark'));
const Tiles = lazy(() => import('./views/tiles'));
const Tickets = lazy(() => import('./views/tickets'));
const Standings = lazy(() => import('./views/standings'));
const Intro = lazy(() => import('./views/intro'));
const LineUp = lazy(() => import('./views/line_up'));
const Specimens = lazy(() => import('./views/specimens'));
const Colophon = lazy(() => import('./views/colophon'));

i18n.use(initReactI18next).init({
  resources: translations,
  lng: 'en',
  fallbackLng: 'en',
  interplation: {
    escapeValue: false
  }
});

const App = () => {
  const [trialModal, handleTrialModal] = useState(false);
  
  const sectionHero = useRef();
  const sectionHistory = useRef();
  const sectionFonts = useRef();
  const sectionSpecimens = useRef();
  const sectionColophon = useRef();

  const handleScroll = target => {
    switch (target) {
      case 'home':
        return sectionHero.current.scrollIntoView({ behavior: 'smooth' });

      case 'about':
        return sectionHistory.current.scrollIntoView({ behavior: 'smooth' });

      case 'fonts':
        return sectionFonts.current.scrollIntoView({ behavior: 'smooth' });

      case 'specimens':
        return sectionSpecimens.current.scrollIntoView({ behavior: 'smooth' });
      
      case 'colophon':
        return sectionColophon.current.scrollIntoView({ behavior: 'smooth' });

      default:
        return sectionHero.current.scrollIntoView({ behavior: 'smooth' });
    }
  }

  async function changeLanguage() {
    const lang = window.location.pathname.toString().replace(/\W*/, '');

    switch (lang) {
      case 'pt':
        return i18n.changeLanguage('pt-BR');
      default:
        return i18n.changeLanguage('en');
    }
  }

  useEffect(() => {
    changeLanguage()
  }, []);

  const checkWebpSupport = () => {
    supportsWebP.then(supported => {
      if (supported)
        return true
      else
        return false
    });
  }

  return (
    <ThemeProvider theme={Theme}>
      <Suspense fallback={<Auth />}>
        <Hero sectionRef={sectionHero} webp={checkWebpSupport()} />
        <Menu scroller={handleScroll}>
          <Intro action={() => handleTrialModal(true)} sectionRef={sectionFonts} webp={checkWebpSupport()} />
          <Features action={() => handleTrialModal(true)} webp={checkWebpSupport()} />
          <History webp={checkWebpSupport()} />
          <Comparison webp={checkWebpSupport()} />
          <Stadium sectionRef={sectionHistory} webp={checkWebpSupport()} />
          <Landmark webp={checkWebpSupport()} />
          <Moments webp={checkWebpSupport()} />
          <Tiles webp={checkWebpSupport()} />
          <Tickets sectionRef={sectionSpecimens} webp={checkWebpSupport()} />
          <LineUp webp={checkWebpSupport()} />
          <Standings webp={checkWebpSupport()} />
          <Specimens webp={checkWebpSupport()} />
          <Colophon sectionRef={sectionColophon} action={() => handleTrialModal(true)} webp={checkWebpSupport()} />
        </Menu>
      </Suspense>

      <Modal title="Get trial fonts" active={trialModal} close={() => handleTrialModal(false)}>
        <Form action={() => handleTrialModal(false)} />
      </Modal>

      <Helmet>
        <meta charSet="utf-8" />
        <link rel="canonical" href="http://pacaembu.font" />
        <meta name="description" content="Pacaembu &#x26BD; Tropical art deco sans serif, a font by Naipe Foundry" />
        <meta name="keywords" content="typography, pacaembu, soccer, football, variable fonts" />
      </Helmet>
    </ThemeProvider>
  );
}

export default App;

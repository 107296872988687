const translations = {
  en: {
    translation: {
      'loader': {
        'title': 'Loading…'
      },
      'header': {
        'author': 'A Naipe Foundry project, by Álvaro Franca and Felipe Casaprima',
        'weight': 'Weight',
        'size': 'Size',
        'alternates': 'Alternates',
        'lineHeight': 'Line height'
      },
      'menu': {
        'fonts': 'Fonts',
        'history': 'Story',
        'specimens': 'Uses',
        'colophon': 'Colophon',
        'buy': 'Buy now'
      },
      'form': {
        'text': 'Disclaimer',
        'name': 'Name',
        'namePlaceholder': 'Your name',
        'email': 'E-mail',
        'emailPlaceholder': 'Your e-mail',
        'acknowledge': 'I agree with the Trial Fonts License Agreement',
        'newsletter': 'Sign me up to the (very infrequent) newsletter',
        'submit': 'Gimme the fonts!'
      },
      'standings': {
        'title': 'Championship standings',
        'position': 'Ranking',
        'positionLetter': 'R',
        'team': 'Teams',
        'teamLetter': 'T',
        'points': 'Points',
        'pointsLetter': 'P',
        'matches': 'Matches',
        'matchesLetter': 'M',
        'wonMatches': 'Won',
        'wonMatchesLetter': 'W',
        'drawMatches': 'Draw',
        'drawMatchesLetter': 'D',
        'lostMatches': 'Lost',
        'lostMatchesLetter': 'L',
        'goalsFor': 'Goals For',
        'goalsForLetter': 'GF',
        'goalsAgainst': 'Goals Against',
        'goalsAgainstLetter': 'GA',
        'goalDifference': 'Goal Difference',
        'goalDifferenceLetter': 'GD',
        'credits': 'Data provided by'
      }
    }
  },
  'pt-BR': {
    translation: {
      'loader': {
        'title': 'Carregando…'
      },
      'header': {
        'author': 'Um projeto da Naipe, por Álvaro Franca e Felipe Casaprima',
        'weight': 'Peso',
        'size': 'Tamanho',
        'alternates': 'Alternativos',
        'lineHeight': 'Entrelinha'
      },
      'menu': {
        'history': 'História',
        'fonts': 'Fontes',
        'specimens': 'Specimens',
        'colophon': 'Colofão',
        'buy': 'Comprar agora'
      },
      'form': {
        'text': 'Aviso em português',
        'name': 'Nome',
        'namePlaceholder': 'Seu nome',
        'email': 'Seu e-mail',
        'emailPlaceholder': 'Seu e-mail',
        'acknowledge': 'Eu concordo com a Licença de Uso de Fontes de Teste',
        'newsletter': 'Inscrever-me para a (não muito frequente) newsletter',
        'submit': 'Baixar as fontes!'
      },
      'standings': {
        'title': 'Tabela de resultados',
        'position': 'Ranking',
        'positionLetter': 'R',
        'team': 'Times',
        'teamLetter': 'T',
        'points': 'Pontos',
        'pointsLetter': 'P',
        'matches': 'Jogos',
        'matchesLetter': 'J',
        'wonMatches': 'Vitórias',
        'wonMatchesLetter': 'V',
        'drawMatches': 'Empates',
        'drawMatchesLetter': 'E',
        'lostMatches': 'Derrotas',
        'lostMatchesLetter': 'D',
        'goalsFor': 'Gols a favor',
        'goalsForLetter': 'GF',
        'goalsAgainst': 'Gols sofridos',
        'goalsAgainstLetter': 'GS',
        'goalDifference': 'Saldo de gols',
        'goalDifferenceLetter': 'SG',
        'credits': 'Dados fornecidos por'
      }
    }
  }
}

export default translations;
